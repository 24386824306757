import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import styled from 'styled-components';

import * as CONFIG from '../../SiteConfig';

import DotPattern from '../assets/images/patterns/dot-grid.png';
import PatternDark from '../assets/images/patterns/escheresque_ste.png';

import ApplicationContext from '../components/particles/context/ApplicationContext';
import * as ROUTES from '../components/particles/constants/routes';

import Layout from '../components/templates/Default';

const IndexWrapper = styled.section`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%:
  justify-content: center;
  max-width: 100%;
  padding: 64px 0;
  width: 100%;
  background-color: ${props =>
    props.mode === 'dark' ? props.theme.black : props.theme.white};
  background-image: url(' ${props =>
    props.mode === 'dark' ? PatternDark : DotPattern} ');
  color: ${props =>
    props.mode === 'dark' ? props.theme.white : props.theme.black};
  .h3 {
    margin-bottom: 32px;
    text-transform: initial;
  }
  > * {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    width: 100%;
    text-align: left;
  }
    /* IE Fix */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
    > * {
      max-width: 1000px;
    }
  }
`;

const LandingPage = () => {
  const { state } = useContext(ApplicationContext);

  return (
    <IndexWrapper mode={state.theme}>
      <h1 className="h3">
      Data Licence
      </h1>
     
    <p>&nbsp;</p>
<p># Terms of Use</p>
<p>&nbsp;</p>
<p>## Copyright Information</p>
<p>&nbsp;</p>
<p>The Ministry of Housing, Communities &amp; Local Government has published these data in order to provide information about the energy performance of buildings.&nbsp; The publication of these data is intended to contribute to delivering the Government&rsquo;s policies to adapt to the effects of climate change and reduce greenhouse gas emissions.&nbsp; It will provide data to facilitate improvements in the energy efficiency of buildings through research, improved management and innovation.</p>
<p>&nbsp;</p>
<p>Please note that this data contains personal data. If processing falls within the scope of the General Data Protection Regulation, or the Data Protection Act 2018, you will become a data controller and must comply with the data protection legislation.</p>
<p>&nbsp;</p>
<p>Any use of address data which is not specified in Royal Mail&rsquo;s copyright notice or covered by one of the copyright exceptions will require an appropriate license.</p>
<p>&nbsp;</p>
<p>### Royal Mail Copyright notice</p>
<p>&nbsp;</p>
<p>These data include addresses and postcodes in which intellectual property rights are owned by Royal Mail Group Limited (&lsquo;address data&rsquo;).&nbsp; The Ministry of Housing, Communities &amp; Local Government has published the address data with the consent of Royal Mail Group Limited, which reserves all its copyright, database rights, trade marks and other intellectual property rights.</p>
<p>&nbsp;</p>
<p>Royal Mail Group Limited permits the use of &lsquo;address data&rsquo; for specific purposes related to the energy performance of buildings which are as follows:</p>
<p>&nbsp;</p>
<p>&nbsp;- The effective management of properties in the private or public sector with a view to promoting energy efficiency (through consideration of factors such as patterns of use, maintenance, repairs, improvements and upgrades of building structure or energy using systems).</p>
<p>&nbsp;- Research to provide data, analysis and statistics intended to improve the understanding of energy efficiency of buildings or to facilitate innovation and improvement in use, design, materials and equipment to this end.</p>
<p>&nbsp;- Research into the effectiveness or impact of energy efficiency improvements including those delivered through Government or other energy improvement programmes.&nbsp; Promoting and marketing energy efficiency improvements that may be made through a Government energy efficiency programme or initiative.</p>
<p>&nbsp;- Promotion and better understanding of the current energy efficiency of buildings and potential improvement in the building sale or rental markets and/or by building occupiers or users.</p>
<p>&nbsp;- To enable enforcement authorities to carry out enforcement duties as required by the Energy Performance of Buildings Regulations (England and Wales) 2012 (SI 2012/3118).</p>
<p>&nbsp;- By local authorities in the exercise of their duties under section 91 of the Building Act 1984, or an approved inspector for the purposes of the inspector&rsquo;s functions under Part 2 of the Building Act 1984.</p>
<p>&nbsp;- For the prevention or detection of crime, the apprehension or prosecution of alleged offenders, any proceedings in a court or tribunal or complying with an order or a court or a tribunal.</p>
<p>&nbsp;</p>
<p>In addition to the purposes set out above there are certain exceptions to copyright which permit you to use copyrighted material for specific and limited purposes. These include non-commercial research and private study, subject to compliance with certain conditions. Further information about these exceptions can be found here:</p>
<p>&nbsp;</p>
<p><a href="https://www.gov.uk/guidance/exceptions-to-copyright">https://www.gov.uk/guidance/exceptions-to-copyright</a></p>
<p>&nbsp;</p>
<p>Where you publish or otherwise share address data with any other person for any of the purposes permitted under this copyright notice, you must include a copy of this copyright notice with the data.</p>
<p>&nbsp;</p>
<p>Any use of address data which is not specified in this notice or covered by one of the copyright exceptions requires an appropriate license and is subject to Royal Mail Group Limited&rsquo;s relevant terms and conditions listed at:</p>
<p><a href="http://www.poweredbypaf.com/">http://www.poweredbypaf.com/</a></p>
<p>By using address data on this website you will be taken to agree to the terms set out in this copyright notice. Failure to comply with this copyright notice may lead to Royal Mail Group Limited taking legal action against you.</p>
<p>&nbsp;</p>
<p>You can contact Royal Mail at <a href="mailto:address.management@royalmail.com">address.management@royalmail.com</a>.</p>
<p>&nbsp;</p>
<p>### Non-Address Data</p>
<p>&nbsp;</p>
<p>All data fields other than the address and postcode data (ADDRESS1, ADDRESS2, ADDRESS3, POSTCODE) available via this website are licensed under the Open Government Licence v3.0.</p>
<p><a href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/">http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/</a></p>
<p>## Data Protection Act 2018</p>
<p>&nbsp;</p>
<p>Address level data concerning the energy performance of buildings constitute personal data for the purposes of the General Data Protection Regulation (GDPR) and Data Protection Act 2018 (DPA 2018). Anyone using personal data must comply with the data protection legislation.</p>
<p>&nbsp;</p>
<p>The data protection principles in the GDPR require that personal data shall be:</p>
<ol>
<li>processed lawfully, fairly and in a transparent manner in relation to the data subject</li>
<li>collected for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes; further processing for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes shall, in accordance with Article 89(1), not be considered to be incompatible with the initial purposes</li>
<li>adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed</li>
<li>accurate and, where necessary, kept up to date; every reasonable step must be taken to ensure that personal data that are inaccurate, having regard to the purposes for which they are processed, are erased or rectified without delay</li>
<li>kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed; personal data may be stored for longer periods insofar as the personal data will be processed solely for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes in accordance with Article 89(1) subject to implementation of the appropriate technical and organisational measures required by this Regulation in order to safeguard the rights and freedoms of the data subject</li>
<li>processed in a manner that ensures appropriate security of the personal data, including protection against unauthorised or unlawful processing and against accidental loss, destruction or damage, using appropriate technical or organisational measures</li>
</ol>
<p>The data controller for any personal data processed shall be responsible for, and be able to demonstrate, compliance with the principles and data protection legislation.</p>
<p>&nbsp;</p>
<p>Please be aware that under the rights of data subjects at article 15 of the GDPR an individual may make a subject access request. This entitles the data subject to obtain information that includes whether his or her data are being processed, a description of the data, the purposes of the processing, to whom it is disclosed and the source of the data.</p>
<p>&nbsp;</p>
<p>Please note that the Ministry of Housing, Communities and Local Government will be adhering to the conditions of the data protection legislation when retaining your e-mail address used for this registration.</p>
<p>&nbsp;</p>
<p>For further information on the operation of the GDPR and DPA2018 please consult the website of the Information Commissioner's Office. <a href="https://ico.org.uk">https://ico.org.uk</a></p>
    </IndexWrapper>
  );
};

export default () => (
  <Layout align="center" page="landing">
    <Helmet
      title={`${CONFIG.SITE_TITLE} | Landing Page`}
      defer={false}
    />
    <LandingPage />
  </Layout>
);
